import TwistTypography from "./TwistTypography";
import LadderTypography from "./LadderTypography";
import TickerTypography from "./TickerTypography";

const CSS = {
  TwistTypography,
  LadderTypography,
  TickerTypography,
};

export default CSS;
