import Arc170 from "./Arc170";
import Displacement from "./Displacement";
import AudioVisualizer from "./AudioVisualizer";

const Three = {
  Arc170,
  Displacement,
  AudioVisualizer,
};

export default Three;
