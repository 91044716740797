import { ShaderMaterial, Color } from "three";
import { extend } from "@react-three/fiber";

export default class PerlinNoiseMaterial extends ShaderMaterial {
  constructor() {
    super({
      uniforms: {
        time: {
          type: "f",
          value: 0.0,
        },
        speed: {
          type: "f",
          value: 0.1,
        },
        elevation: {
          type: "f",
          value: 1,
        },
        noise_range: {
          type: "f",
          value: 0,
        },
        offset: {
          type: "f",
          value: 1,
        },
        perlin_passes: {
          type: "f",
          value: 1,
        },
        sombrero_amplitude: {
          type: "f",
          value: 0.3,
        },
        sombrero_frequency: {
          type: "f",
          value: 10.0,
        },
        line_color: {
          type: "c",
          value: new Color("#ffffff"),
        },
      },
      vertexShader: `
        vec3 mod289(vec3 x) {
          return x - floor(x * (1.0 / 289.0)) * 289.0;
        }
        vec4 mod289(vec4 x) {
          return x - floor(x * (1.0 / 289.0)) * 289.0;
        }
        vec4 permute(vec4 x) {
          return mod289(((x * 34.0) + 1.0) * x);
        }
        vec4 taylorInvSqrt(vec4 r) {
          return 1.79284291400159 - 0.85373472095314 * r;
        }
        vec3 fade(vec3 t) {
          return t * t * t * (t * (t * 6.0 - 15.0) + 10.0);
        }
        float cnoise(vec3 P) {
          vec3 Pi0 = floor(P);
          vec3 Pi1 = Pi0 + vec3(1.0);
          Pi0 = mod289(Pi0);
          Pi1 = mod289(Pi1);
          vec3 Pf0 = fract(P);
          vec3 Pf1 = Pf0 - vec3(1.0);
          vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
          vec4 iy = vec4(Pi0.yy, Pi1.yy);
          vec4 iz0 = Pi0.zzzz;
          vec4 iz1 = Pi1.zzzz;
          vec4 ixy = permute(permute(ix) + iy);
          vec4 ixy0 = permute(ixy + iz0);
          vec4 ixy1 = permute(ixy + iz1);
          vec4 gx0 = ixy0 * (1.0 / 7.0);
          vec4 gy0 = fract(floor(gx0) * (1.0 / 7.0)) - 0.5;
          gx0 = fract(gx0);
          vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
          vec4 sz0 = step(gz0, vec4(0.0));
          gx0 -= sz0 * (step(0.0, gx0) - 0.5);
          gy0 -= sz0 * (step(0.0, gy0) - 0.5);
          vec4 gx1 = ixy1 * (1.0 / 7.0);
          vec4 gy1 = fract(floor(gx1) * (1.0 / 7.0)) - 0.5;
          gx1 = fract(gx1);
          vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
          vec4 sz1 = step(gz1, vec4(0.0));
          gx1 -= sz1 * (step(0.0, gx1) - 0.5);
          gy1 -= sz1 * (step(0.0, gy1) - 0.5);
          vec3 g000 = vec3(gx0.x, gy0.x, gz0.x);
          vec3 g100 = vec3(gx0.y, gy0.y, gz0.y);
          vec3 g010 = vec3(gx0.z, gy0.z, gz0.z);
          vec3 g110 = vec3(gx0.w, gy0.w, gz0.w);
          vec3 g001 = vec3(gx1.x, gy1.x, gz1.x);
          vec3 g101 = vec3(gx1.y, gy1.y, gz1.y);
          vec3 g011 = vec3(gx1.z, gy1.z, gz1.z);
          vec3 g111 = vec3(gx1.w, gy1.w, gz1.w);
          vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
          g000 *= norm0.x;
          g010 *= norm0.y;
          g100 *= norm0.z;
          g110 *= norm0.w;
          vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
          g001 *= norm1.x;
          g011 *= norm1.y;
          g101 *= norm1.z;
          g111 *= norm1.w;
          float n000 = dot(g000, Pf0);
          float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
          float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
          float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
          float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
          float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
          float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
          float n111 = dot(g111, Pf1);
          vec3 fade_xyz = fade(Pf0);
          vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
          vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
          float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x);
          return 2.2 * n_xyz;
        }
        float pnoise(vec3 P, vec3 rep) {
          vec3 Pi0 = mod(floor(P), rep);
          vec3 Pi1 = mod(Pi0 + vec3(1.0), rep);
          Pi0 = mod289(Pi0);
          Pi1 = mod289(Pi1);
          vec3 Pf0 = fract(P);
          vec3 Pf1 = Pf0 - vec3(1.0);
          vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
          vec4 iy = vec4(Pi0.yy, Pi1.yy);
          vec4 iz0 = Pi0.zzzz;
          vec4 iz1 = Pi1.zzzz;
          vec4 ixy = permute(permute(ix) + iy);
          vec4 ixy0 = permute(ixy + iz0);
          vec4 ixy1 = permute(ixy + iz1);
          vec4 gx0 = ixy0 * (1.0 / 7.0);
          vec4 gy0 = fract(floor(gx0) * (1.0 / 7.0)) - 0.5;
          gx0 = fract(gx0);
          vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
          vec4 sz0 = step(gz0, vec4(0.0));
          gx0 -= sz0 * (step(0.0, gx0) - 0.5);
          gy0 -= sz0 * (step(0.0, gy0) - 0.5);
          vec4 gx1 = ixy1 * (1.0 / 7.0);
          vec4 gy1 = fract(floor(gx1) * (1.0 / 7.0)) - 0.5;
          gx1 = fract(gx1);
          vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
          vec4 sz1 = step(gz1, vec4(0.0));
          gx1 -= sz1 * (step(0.0, gx1) - 0.5);
          gy1 -= sz1 * (step(0.0, gy1) - 0.5);
          vec3 g000 = vec3(gx0.x, gy0.x, gz0.x);
          vec3 g100 = vec3(gx0.y, gy0.y, gz0.y);
          vec3 g010 = vec3(gx0.z, gy0.z, gz0.z);
          vec3 g110 = vec3(gx0.w, gy0.w, gz0.w);
          vec3 g001 = vec3(gx1.x, gy1.x, gz1.x);
          vec3 g101 = vec3(gx1.y, gy1.y, gz1.y);
          vec3 g011 = vec3(gx1.z, gy1.z, gz1.z);
          vec3 g111 = vec3(gx1.w, gy1.w, gz1.w);
          vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
          g000 *= norm0.x;
          g010 *= norm0.y;
          g100 *= norm0.z;
          g110 *= norm0.w;
          vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
          g001 *= norm1.x;
          g011 *= norm1.y;
          g101 *= norm1.z;
          g111 *= norm1.w;
          float n000 = dot(g000, Pf0);
          float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
          float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
          float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
          float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
          float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
          float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
          float n111 = dot(g111, Pf1);
          vec3 fade_xyz = fade(Pf0);
          vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
          vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
          float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x);
          return 2.2 * n_xyz;
        }
        float rand(vec2 n) {
          return 0.5 + 0.5 *
            fract(sin(dot(n.xy, vec2(12.9898, 78.233))) * 43758.5453);
        }
        varying vec2 v_uv;
        varying vec3 v_line_color;
        uniform float time;
        uniform float speed;
        uniform float elevation;
        uniform float noise_range;
        uniform float perlin_passes;
        uniform float sombrero_amplitude;
        uniform float sombrero_frequency;
        uniform vec3 line_color;
        varying float z;
        #define M_PI 3.1415926535897932384626433832795
        void main() {
          gl_PointSize = 1.;
          v_uv = uv;
          v_line_color = line_color;
          // First perlin passes
          float displacement = pnoise(.4 * position + vec3(0, speed * time, 0), vec3(100.0)) * 1. * noise_range;
          if (perlin_passes > 2.0) {
            displacement += pnoise(2. * position + vec3(0, speed * time * 5., 0), vec3(100.)) * .3 * noise_range;
            displacement += pnoise(8. * position + vec3(0, speed * time * 20., 0), vec3(100.)) * .1 * noise_range;
          } else if (perlin_passes > 1.0) {
            displacement += pnoise(8. * position + vec3(0, speed * time * 20., 0), vec3(100.)) * .1 * noise_range;
          }
          float distance = sqrt(((uv.x - 0.5) * (uv.x - 0.5)) + ((uv.y - 0.5) * (uv.y - 0.5)));
          float z = (sombrero_amplitude * sin(((time * 0.5 * speed) - (distance * sombrero_frequency)) * M_PI));
          // Sinus
          displacement = displacement + (sin(position.x / 2. - M_PI / 2.)) * elevation;
          vec3 newPosition = vec3(position.x, position.y, displacement + z);
          gl_Position = projectionMatrix * modelViewMatrix * vec4(newPosition, 1.);
          z = newPosition.z;
        }
      `,
      fragmentShader: `
        varying vec2 v_uv;
        varying vec3 v_line_color;
        varying float z;
        #define M_PI 3.1415926535897932384626433832795
        void main() {
          vec4 temp;
          float alpha = sin(v_uv.y * M_PI) / 4.;
          temp = vec4(v_line_color, alpha);
          gl_FragColor = temp;
        }
      `,
    });
  }

  get time() {
    return this.uniforms.time.value;
  }

  set time(v) {
    this.uniforms.time.value = v;
    return this.uniforms.time.value;
  }

  get speed() {
    return this.uniforms.speed.value;
  }

  set speed(v) {
    this.uniforms.speed.value = v;
    return this.uniforms.speed.value;
  }

  get elevation() {
    return this.uniforms.elevation.value;
  }

  set elevation(v) {
    this.uniforms.elevation.value = v;
    return this.uniforms.elevation.value;
  }

  get noiseRange() {
    return this.uniforms.noise_range.value;
  }

  set noiseRange(v) {
    this.uniforms.noise_range.value = v;
    return this.uniforms.noise_range.value;
  }

  get offset() {
    return this.uniforms.offset.value;
  }

  set offset(v) {
    this.uniforms.offset.value = v;
    return this.uniforms.offset.value;
  }

  get perlinPasses() {
    return this.uniforms.perlin_passes.value;
  }

  set perlinPasses(v) {
    this.uniforms.perlin_passes.value = v;
    return this.uniforms.perlin_passes.value;
  }

  get sombreroAmplitude() {
    return this.uniforms.sombrero_amplitude.value;
  }

  set sombreroAmplitude(v) {
    this.uniforms.sombrero_amplitude.value = v;
    return this.uniforms.sombrero_amplitude.value;
  }

  get sombreroFrequency() {
    return this.uniforms.sombrero_frequency.value;
  }

  set sombreroFrequency(v) {
    this.uniforms.sombrero_frequency.value = v;
    return this.uniforms.sombrero_frequency.value;
  }

  get lineColor() {
    return this.uniforms.line_color.value;
  }

  set lineColor(v) {
    this.uniforms.line_color.value = v;
    return this.uniforms.line_color.value;
  }
}

extend({ PerlinNoiseMaterial });
